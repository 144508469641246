import React from "react";

import Layout from "../components/layout";

const SolutionsPage = () => (
  <Layout noRobots={true}>
    <div className="document-page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="document-bold">Regulamin</h1>
            <h2 className="document-bold">Korzystania z Aplikacji kidsup</h2>
            <h3 className="document-bold">
              §1
              <br />
              Definicje pojęć
            </h3>
            <h4>
              1.{" "}
              <span className="document-bold document-bigger-h4">
                Spółka Nastap Tech
              </span>{" "}
              – Nastap Tech Spółka Akcyjna. (61-409) Poznań przy ul. Lelewela 6A,
              wpisaną do Krajowego Rejestru Sądowego prowadzonego przez Sąd
              Rejonowy - Nowe Miasto i Wilda w Poznaniu VIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego pod numerem KRS 0000699834,
              posiadającego nr NIP: 6060101216 i nr REGON: 368568706, zwany
              dalej – usługodawca aplikacji.
            </h4>
            <h4>
              2.{" "}
              <span className="document-bold document-bigger-h4">
                Aplikacja
              </span>{" "}
              – Program komputerowy o nazwie kidsup. Dostępna w wersji webowej
              oraz w wersji mobilnej w sklepach App Store i Google Play.
            </h4>
            <h4>
              3.{" "}
              <span className="document-bold document-bigger-h4">
                Regulamin
              </span>{" "}
              – Regulamin korzystania z aplikacji kidsup, dostępny na stronie
              www.kidsup.pl/regulamin
            </h4>
            <h4>
              4.{" "}
              <span className="document-bold document-bigger-h4">Placówka</span>{" "}
              - przedszkole lub żłobek korzystające z Aplikacji, niezależnie od
              formy organizacyjno-prawnej, w ramach której Placówka funkcjonuje.
            </h4>
            <h4>
              5.{" "}
              <span className="document-bold document-bigger-h4">
                Użytkownik
              </span>{" "}
              – osoba fizyczna – w tym konsument - posiadająca pełną zdolność do
              czynności prawnych, a w wypadkach przewidzianych przez przepisy
              powszechnie obowiązujące.
            </h4>
            <h4>
              6.{" "}
              <span className="document-bold document-bigger-h4">
                Hasło kod PIN
              </span>{" "}
              – dane dostępowe do aplikacji, wysyłany do użytkownika pod
              wskazany wcześniej numer telefonu, służy do pierwszego logowania w
              aplikacji.
            </h4>
            <h4>
              7.{" "}
              <span className="document-bold document-bigger-h4">
                Dane logowania
              </span>{" "}
              – dane osobowe (numer telefonu i kod pin), które są wymagane do
              logowania w aplikacji.
            </h4>
            <h4>
              8. <span className="document-bold document-bigger-h4">Umowa</span>{" "}
              - umowa o świadczenie usług, na zasadach określonych w
              Regulaminie, zawarta na czas określony.
            </h4>
            <h4>
              9. <span className="document-bold document-bigger-h4">RODO</span>{" "}
              - Rozporządzenie w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              taki danych. - Rozporządzenie Parlamentu Europejskiego i Rady (EU)
              2016/679 z dnia 27 kwietnia 2016 r. Oraz uchylenia dyrektywy
              95/46/WE (ogólne rozporządzenie o ochronie danych)
            </h4>
            <h4>
              10.{" "}
              <span className="document-bold document-bigger-h4">
                Ustawa o świadczeniu usług drogą elektroniczną
              </span>{" "}
              - ustawa z dnia 18 lipca 2002 roku o świadczeniu usług drogą
              elektroniczną (tekst jeden.:Dz.U. z 2017 r. poz. 1219 z późno. zm)
            </h4>
            <h4>
              11.{" "}
              <span className="document-bold document-bigger-h4">
                Pliki cookies
              </span>{" "}
              - informacje tekstowe na urządzeniu użytkownika, które mogą być
              odczytywane przez sytem teleinformatyczny.
            </h4>

            <h3 className="document-bold">
              §2
              <br />
              Postanowienia wstępne
            </h3>
            <h4>
              1. Uzyskując dostęp do aplikacji Spółki kidsup (w tym aplikacji
              dla urządzeń przenośnych) udostępnionych przez kidsup (zwanych
              łącznie „usługami”) Użytkownik akceptuje niniejszy Regulamin
              („Regulamin”). Spółce kidsup przysługuje tytuł prawny do
              niniejszych usług, lub są przez nią kontrolowane.{" "}
              <span className="document-bold">
                Niniejszy Regulamin określa prawa i obowiązki użytkownika. Jeśli
                użytkownik nie zgadza się z postanowieniami niniejszego
                Regulaminu, nie powinien korzystać z aplikacji ani uzyskiwać do
                nich dostępu.
              </span>
            </h4>
            <h4>
              2. Postanowienia Regulaminu dotyczące Spółki kidsup należy
              odpowiednio stosować do właściciela autorskich praw majątkowych do
              Aplikacji, którym jest Spółka Akcyjna kidsup z siedzibą w Obornikach
              (64-600) przy ul. Kalinowej 6 o nr NIP: 6060101216.
            </h4>
            <h4>
              3. Zabronione jest udostępnianie za pośrednictwem aplikacji treści
              zawierających elementy przemocy, nagości, częściowej nagości,
              treści dyskryminacyjnych, niezgodnych z prawem, promujących
              nienawiść, treści pornograficznych oraz zdjęć i innych treści o
              podtekście seksualnym.
            </h4>
            <h4>
              4. Użytkownik ponosi odpowiedzialność za każdą czynność wykonywaną
              za pośrednictwem jego konta w aplikacji i zobowiązuje się nie
              przekazywać swojego konta, nazwy użytkownika ani jakichkolwiek
              innych praw do konta innym osobom.
            </h4>
            <h4>
              5. Zabronione jest wyłudzanie, gromadzenie lub wykorzystywanie
              danych logowania innych użytkowników aplikacji kidsup.
            </h4>
            <h4>
              6. Użytkownik jest odpowiedzialny za utrzymywanie swojego hasła w
              tajemnicy i dbanie o jego bezpieczeństwo.
            </h4>
            <h4>
              7. Zabrania się wykorzystywania usług do nielegalnych lub
              niedozwolonych działań. Użytkownik zobowiązuje się przestrzegać
              wszystkich przepisów prawa, warunków i regulacji mających
              zastosowanie do korzystania z usług i z treści użytkownika
              (rozumianych zgodnie z poniższą definicją), w tym między innymi
              przepisów dotyczących praw autorskich.
            </h4>
            <h4>
              8. Zabronione jest wprowadzanie zmian do serwisu, dostosowywanie
              lub ulepszanie go, a także modyfikowanie lub dostosowywanie innych
              witryn, aby fałszywie wykazywały związek z usługą lub aplikacją
              kidsup.
            </h4>
            <h4>
              9. Spółka kidsup zastrzega sobie prawo do wprowadzania zmian w
              aplikacji, a także do zablokowania dostępu Użytkownika do
              aplikacji w uzasadnionych przypadkach bez uprzedniego
              powiadomienia użytkownika. Za uzasadniony przypadek będzie
              uznawane w szczególności korzystanie przez użytkownika z aplikacji
              kidsup niezgodnie z postanowieniami niniejszego regulaminu lub
              sprzecznie z obowiązującymi przepisami prawa.
            </h4>
            <h4>
              10. Użytkownik może dezaktywować swoje konto. W przypadku
              dokonania przez Użytkownika dezaktywacji konta lub jego usunięcia,
              dane Użytkownika zapisane na tym koncie zostaną trwale usunięte.
            </h4>
            <h4>
              11. Zgodnie z Regulaminem po zakończeniu korzystania z aplikacji
              wszystkie licencje i inne nabyte przez Użytkownika prawa utracą
              ważność.
            </h4>
            <h4>
              12. Spółka kidsup zastrzega sobie prawo do wprowadzania od czasu
              do czasu zmian do Regulaminu („
              <span className="document-bold">zmiany Regulaminu</span>”) według
              własnego uznania. Użytkownik zostanie poinformowany z odpowiednim
              wyprzedzeniem o zmianach Regulaminu, zanim wejdą one w życie,
              chyba że zmiany te będą umotywowane względami prawnymi lub
              administracyjnymi. Użytkownik wyraża zgodę na otrzymywanie
              powiadomień o zmianach Regulaminu poprzez ich publikację na
              stronach aplikacji i akceptuje fakt, że korzystanie z usług (lub
              podejmowanie innych określonych przez nas czynności) po wejściu w
              życie zmianami Regulaminu oznacza akceptację tych zmian. W związku
              z tym zalecamy zapoznanie się z postanowieniami niniejszego
              Regulaminu i wszelkimi wprowadzonymi zmian przed skorzystaniem z
              usług. Zmiany Regulaminu wchodzą w życie w momencie ich publikacji
              bądź w późniejszym momencie określonym w niej i od tego momentu
              obowiązują w odniesieniu do korzystania z aplikacji. Przed
              wejściem w życie zmiany Regulaminu wszystkie zaistniałe spory będą
              rozwiązywane w oparciu o obecny Regulamin.
            </h4>

            <h3 className="document-bold">
              §3
              <br />
              Zasady korzystania z aplikacji
            </h3>
            <h4>
              1. Spółka kidsup świadczy usługę drogą elektroniczna - ustawa z
              dnia 18 lipca 2002 roku o świadczeniu usług drogą
              elektroniczną (tekst jeden.:Dz.U. z 2017 r. poz. 1219 z późno. zm)
            </h4>
            <h4>
              2. Aplikacja kidsup jest dostępna dla wszystkich użytkowników, w
              wersji webowej lub mobilnej.
            </h4>
            <h4>
              3. Dostęp do aplikacji pod wskazanymi adresami: komputer -{" "}
              <a href={"https://www.kidsup.pl"}>https://www.kidsup.pl</a> , App
              Store -{" "}
              <a
                href={
                  "https://apps.apple.com/us/app/kidsup/id1451646951?l=pl&ls=1"
                }
              >
                https://apps.apple.com/us/app/kidsup/id1451646951?l=pl&ls=1
              </a>{" "}
              , Google Play -{" "}
              <a
                href={
                  "https://play.google.com/store/apps/details?id=pl.kidsup.kidsup&hl=pl"
                }
              >
                https://play.google.com/store/apps/details?id=pl.kidsup.kidsup&hl=pl
              </a>{" "}
            </h4>
            <h4>
              4. Aplikacji działa w trybie online, tj. Kiedy użytkownik posiada
              dostęp do Internetu.{" "}
            </h4>
            <h4>
              5. Usługodawca w ramach aplikacji umożliwia komunikację pomiędzy
              Placówką a użytkownikiem. Świadczone usługi drogą elektroniczna
              mają charakter wyłącznie informacyjny. Warunkiem zawarcia umowy z
              użytkownikiem jest udzielenie licencji na korzystanie z aplikacji.
            </h4>
            <h4>
              6. Usługodawca zastrzega możliwość sporządzenia konkretnych usług
              regulaminów odrębnych do niniejszego.
            </h4>

            <h3 className="document-bold">
              §4
              <br />
              Zakładanie konta w aplikacji
            </h3>
            <h4>1. Administrator placówki tworzy konta użytkowników. </h4>
            <h4>
              2. Dane do stworzenia konta, które są wymagane (imię i nazwisko,
              numer telefonu)
            </h4>
            <h4>
              3. Pod wskazany numer telefonu jest wysyłany kod PIN, który
              połączony z numerem telefonu weryfikuje użytkownika. Prawidłowo
              uzupełnione dane pozwalają na logowanie do aplikacji.
            </h4>
            <h4>
              4. Użytkownik ma pełen wgląd do swoich danych oraz dziecka w
              aplikacji.
            </h4>
            <h4>
              5. Poprzez korzystanie z aplikacji lub uzyskanie do niego dostępu
              użytkownik gwarantuje, że jego działania są zgodne z przepisami
              obowiązującymi w prawie polskim.
            </h4>

            <h3 className="document-bold">
              §5
              <br />
              Dane osobowe
            </h3>
            <h4>
              1. Administratorem danych osobowych jest placówka, do której
              uczęszcza dziecko. Zgodnie z art. 37 RODO - Rozporządzenie
              Parlamentu Europejskiego i Rady (EU) 2016/679 z dnia 27 kwietnia
              2016 r. Oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
              ochronie danych)
            </h4>
            <h4>
              2. Spółka kidsup jest procesorem podmiotem przetwarzającym dane,
              które otrzymuje od Administratora placówki i wykonuje na jego
              zlecenie umowę o świadczeniu usługi drogą elektroniczna.
            </h4>
            <h4>
              3. Administrator danych przetwarza udostępnione mu dane osobowe
              Użytkownika , o których mowa w zakresie niezbędnym do organizacji
              i funkcjonowania Aplikacji przez Użytkownika, oraz w celach,
              informacyjnych i statystycznych, na co Użytkownik akceptując
              niniejszy Regulamin wyraża swoją zgodę.
            </h4>
            <h4>
              4. Na podstawie dodatkowo wyrażonej przez Użytkownika zgody (w
              szczególności poprzez zamówienie przez Użytkownika usługi
              Newslettera), dane te mogą być przez Administratora wykorzystywane
              celem przekazywania Użytkownikowi za pośrednictwem Aplikacji lub
              poza nią niezamówionych informacji w rozumieniu art. 10 ust. 1
              Ustawy z dnia 18 lipca 2002r. o świadczeniu usług drogą
              elektroniczną, dotyczących oferty Administratora.
            </h4>
            <h4>
              5. Dane osobowe mogą być udostępnione organom władzy publicznej
              upoważnionym do dostępu do danych na podstawie właściwych
              przepisów prawa.
            </h4>
            <h4>
              6. Użytkownik ma prawo wglądu do swoich danych oraz ich
              modyfikacji.
            </h4>
            <h4>
              7. Administrator przetwarza dane osobowe w sposób zgodny z
              obowiązującymi przepisami prawa.
            </h4>

            <h3 className="document-bold">
              §6
              <br />
              Wymagania sprzętowe
            </h3>
            <h4>
              1. Do korzystania z Aplikacji niezbędne jest posiadanie dostępu do
              sieci Internet. Aplikacja jest dostępna dla urządzeń mobilnych i
              można pobrać tylko i wyłącznie z autoryzowanych sklepów (App Store
              lub Google Play) a także na urządzenia stacjonarne (komputery).
            </h4>
            <h4>
              2. Urządzenia mobilne, na których może zostać uruchomiona
              aplikacja musi spełniać wymagania techniczne: aplikacja w wersji
              iOS z App Store - iOS w wersji min 9.0, aplikacja w wersji Android
              z Google Play - w wersji Android 5.0
            </h4>
            <h4>
              3. Urządzenia stacjonarne, na których może zostać uruchomiona
              aplikacja w wersji webowej, posiadać zainstalowaną przeglądarkę
              internetową tj. (Chrome, Safari, Opera, Firefox, Mozilla) -
              dostarczoną przez producenta w najnowszej wersji.
            </h4>
            <h4>
              4. Spółka kidsup nie ponosi odpowiedzialności za niedziałanie lub
              nienależyte działanie Aplikacji na urządzeniu niespełniającym
              wymagań technicznych określonych powyżej.
            </h4>

            <h3 className="document-bold">
              §7
              <br />
              Prawa własności intelektualnej
            </h3>
            <h4>
              1. Nazwa i logo kidsup są znakami towarowymi należącymi do Spółki
              kidsup. Zabrania się ich kopiowania, imitowania oraz
              wykorzystywania w całości lub w części bez uprzedniej pisemnej
              zgody Spółki kidsup.
            </h4>
            <h4>
              2. Aplikacja jest chroniona prawami autorskimi, powielanie
              zawartości grafik, zdjeć, tekstów jest zabronione.
            </h4>

            <h3 className="document-bold">
              §8
              <br />
              Wyłączenie odpowiedzialności
            </h3>
            <h4>
              1. Aplikacja nie gwarantuje, że treści będą „wolne od błędów”,
              mogą zawierać błędy wyświetlanych informacji. W sytuacji takiej
              należy to zgłosić pod adres:{" "}
              <a href={"mailto:kontakt@kidsup.pl"}>kontakt@kidsup.pl</a>
            </h4>
            <h4>
              2. Ponadto Spółka kidsup - niniejszym wykluczają wszelkie
              gwarancję prawa do bezproblemowego użytkowania. Zapis dotyczy
              urządzenia użytkownika końcowego, który jest jego właścicielem. W
              sytuacji, której problem z użytkowaniem aplikacji występuje należy
              to zgłosić pod adres:{" "}
              <a href={"mailto:kontakt@kidsup.pl"}>kontakt@kidsup.pl</a>
            </h4>
            <h4>
              3. Poprzez korzystanie z aplikacji lub uzyskanie do niego dostępu
              użytkownik gwarantuje, że jego działania są zgodne z przepisami
              obowiązującymi w polski prawie.
            </h4>

            <h3 className="document-bold">
              §9
              <br />
              Reklamacje
            </h3>
            <h4>
              1. Wszelkie zapytania, sugestie, reklamacje, oświadczenia oraz
              informacje o zaistniałych problemach dotyczących Aplikacji,
              Użytkownik może kierować pocztą elektroniczną na adres:
              kontakt@kidsup.pl
            </h4>
            <h4>
              2. Szczegółowe informacje dotyczące możliwości skorzystania przez
              Użytkownika będącego Konsumentem z pozasądowych sposobów
              rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady
              dostępu do tych procedur dostępne są na stronie internetowej
              Urzędu Ochrony Konkurencji i Konsumentów pod adresem internetowym
              stanowiącym jedną z zakładek www.uokik.gov.pl:{" "}
              <a href="https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">
                {" "}
                https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php
              </a>
            </h4>

            <h3 className="document-bold">
              §10
              <br />
              Pliki cookies
            </h3>
            <h4>
              1. Administratorem danych osobowych jest Placówka, do której
              uczęszczają dzieci. Placówka powierza dane Administratorowi w celu
              zapewnienia możliwości korzystania z aplikacji.
            </h4>
            <h4>
              2. Aplikacja wersja webowa wykorzystuje pliki cookies do odczytu i
              prawidłowego funkcjonowania aplikacji w celach statystycznych.
            </h4>

            <h3 className="document-bold">
              §11
              <br />
              Postanowienia końcowe
            </h3>
            <h4>
              1. Spółka kidsup zastrzega sobie prawo do zmiany treści Regulaminu
              z ważnych powodów – w szczególności w obliczu nowelizacji
              przepisów powszechnie obowiązującego prawa.
            </h4>
            <h4>
              2. Spółka kidsup zastrzega sobie możliwość sporządzania
              konkretnych usług regulaminu odrębnych do niniejszego regulaminu.
            </h4>
            <h4>
              3. W sprawach nieuregulowanych w treści Regulaminu zastosowanie
              będą miały przepisy powszechnie obowiązującego prawa polskiego – w
              tym w szczególności Kodeksu cywilnego i Ustawy o prawach
              Konsumenta oraz Ustawy o prawie autorskim i prawach pokrewnych.
            </h4>
            <h4>
              4. W przypadku zaistniałych sporów pomiędzy Klientem i Sprzedawcą,
              jeżeli przepisy powszechnie obowiązującego prawa będą to
              umożliwiały, będą one poddawane pod rozstrzygniecie przed Sądem
              rzeczypospolitej Polskiej, właściwym ze względu na siedzibę Spółki
              kidsup.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default SolutionsPage;
